body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;

  background: #292e34;
  font-weight: 600 !important;
  min-height: 100vh;
}

.primary-menu.bg-transparent {
  box-shadow: none;
  border-bottom: 1px solid rgba(250, 250, 250, 0.3);
}

.navbar {
  background-color: #333;
  color: #fff;
  padding: 10px;
}

.navbar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  text-align: left;
}

.navbar ul li {
  display: inline-block;
  margin-left: 10px;
}

.navbar ul li a {
  color: #fff;
  text-decoration: none;
}

.title {
  text-align: center;
  margin-top: 20px;
}

.powered-by {
  text-align: center;
  margin-top: 20px;
  color: #999;
  font-size: 12px;
}

.description {
  text-align: center;
  margin-top: 10px;
  border: 1px solid #ccc;
  padding: 10px;
}

.row {
  display: flex;
  justify-content: center;
}

.card {
  width: 300px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
  text-align: center;
}

.card img {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.card-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card-row {
  display: flex;
  justify-content: center;
}

.card-column p {
  padding: 0 10px;
  margin-bottom: 5px;
}

.toolTip {
  padding: 0 10px;
  border: solid 2px #333;
  border-radius: 20px;
}

.card-info {
  position: relative;
  display: inline-block;
}

.card-info span {
  visibility: hidden;
  width: 200px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
}

.card-info:hover span {
  visibility: visible;
  opacity: 1;
}

.card button {
  margin-top: 10px;
}

.small-title {
  text-align: center;
  font-size: 18px;
  margin-top: 20px;
}

.table-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

table {
  border-collapse: collapse;
  margin-bottom: 30px;
}

table th {
  font-weight: bold;
}

table td,
table th {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}

.card-description {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin-top: 10px;
}

button {
  margin: 2px 8px;
}

.btn:hover {
  background-color: #fff !important;
}

@media (max-width: 991.98px) {
  .primary-menu ul.navbar-nav > li > .nav-link:not(.btn) {
    color: #252b33;
    transition: all 0.2s ease;
  }
  .nb-btn {
    margin-top: 1em !important;
  }
  .primary-menu .navbar-collapse ul.navbar-nav li {
    display: block;
    border-bottom: 1px solid #eee;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: rgb(238, 238, 238);
    margin: 0;
    padding: 0;
  }
  #header .navbar-collapse {
    position: absolute;
    top: 99%;
    right: 0;
    left: 0;
    background: #fff;
    background-image: initial;
    background-position-x: initial;
    background-position-y: initial;
    background-size: initial;
    background-repeat-x: initial;
    background-repeat-y: initial;
    background-attachment: initial;
    background-origin: initial;
    background-clip: initial;
    background-color: rgb(255, 255, 255);
    margin-top: 0px;
    padding: var(--bs-gutter-x, 0.85rem);
    z-index: 1000;
    -webkit-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    overflow-y: auto;
    max-height: 85vh;
  }
  .navbar-toggler span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    border-radius: 2px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
  }
}
